/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      meetingInfo: {
        roomName: '',
        time: '',
        summary: '',
        lname: '',
        state: ''
      },
      usersList: [],
      all: [[1, 2, 3, 4, 5], [1, 2]],
      bh: ''
    };
  },
  methods: {
    //selectByPrimaryDetail
    getData: function getData() {
      var _this = this;

      var param = {
        bh: this.bh,
        userSerial: this.$utils.Store.getItem('userSerial')
      };
      this.$api.Meeting.selectByPrimaryDetail(param).then(function (res) {
        var code = res.code,
            msg = res.msg,
            data = res.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.loadHide();

          _this.$utils.Tools.toastShow(msg);
        } else {
          var item = data;
          _this.meetingInfo.roomName = item.roomName;
          _this.meetingInfo.summary = item.summary;
          _this.meetingInfo.time = item.kssj.substring(0, 16) + '-' + item.jssj.substring(11, 16);
          _this.meetingInfo.lname = item.lname;
          _this.meetingInfo.state = item.state; //  this.all = item.meetUsers

          var list = item.meetUsers;
          _this.usersList = [];
          var dis = Math.ceil(list.length / 5);

          for (var i = 0; i < dis; i++) {
            var newList = list.slice(i * 5, (i + 1) * 5);

            _this.usersList.push(newList);
          } // this.usersList = this.all

        }
      });
    }
  },
  created: function created() {
    this.bh = this.$route.query.bh || this.$utils.Tools.getUrlParams('state');
    this.getData();
  },
  mounted: function mounted() {}
};